import request, {
  SERVICE_MODULE_MAP
} from "@/utils/request";

export function addressesListApi(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/addresses",
    method: "get",
    data: params
  });
}

export function addAddressApi(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/addresses",
    method: "post",
    data
  });
}

export function updateAddressApi(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/addresses/" + data.id,
    method: "post",
    data
  });
}

export function deleteAddressApi(id) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/addresses/" + id,
    method: "delete",
  });
}

export function setDefaultAddressApi(id) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/addresses/" + id + "/default",
    method: "post",
  });
}