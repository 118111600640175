<template>
  <div>
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      status-icon
      :rules="rules"
      :label-position="'top'"
      class="flex flex-1 "
    >
      <div class="w-full">
        <el-form-item class="w-full" label="收货人" prop="receiver">
          <el-input
            v-model="ruleForm.receiver"
            type="text"
            maxlength="50"
            placeholder="最多50个字"
          ></el-input>
        </el-form-item>
        <el-form-item class="w-full" label="联系电话" prop="phone">
          <el-input
            v-model="ruleForm.phone"
            type="text"
            maxlength="11"
            placeholder="请输入联系人姓名"
          ></el-input>
        </el-form-item>
        <el-form-item class="w-full" label="所在地区" prop="receiver">
          <SelectArea style="width: 100%" @change="areaChange" />
        </el-form-item>
        <el-form-item class="w-full" label="详细地址" prop="address">
          <el-input
            v-model="ruleForm.address"
            type="textarea"
            :rows="2"
            maxlength="50"
            placeholder="最多50个字"
          ></el-input>
        </el-form-item>
        <el-form-item class="w-full">
          <el-checkbox v-model="ruleForm.isDefault">默认地址</el-checkbox>
        </el-form-item>
      </div>
    </el-form>
    <div class=" flex justify-end space-x-16 border-t pt-8">
      <div
        class="border px-16 py-2 rounded-full border-gray-900 cursor-pointer"
        @click="$emit('cancle')"
      >
        取消
      </div>
      <div
        class="border px-16 py-2 rounded-full border-warning bg-warning text-white cursor-pointer"
        @click="submit"
      >
        确定
      </div>
    </div>
  </div>
</template>

<script>
import { mobileValidate } from "@/utils/validate";
import SelectArea from "@/components/selectArea/SelectArea.vue";
import { addAddressApi, updateAddressApi } from "@/api/address";

export default {
  props: {
    addressInfo: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    SelectArea,
  },
  data() {
    return {
      ruleForm: {
        receiver: "",
        phone: "",
        provinceId: "",
        address: "",
        isDefault: false,
      },
      rules: {
        receiver: [
          { required: true, message: "请填写收货人", trigger: "blur" },
        ],
        phone: [{ required: true, validator: mobileValidate, trigger: "blur" }],
        provinceId: [
          { required: true, message: "请填写所在地区", trigger: "blur" },
        ],
        address: [
          { required: true, message: "请填写详细地址", trigger: "blur" },
        ],
      },
    };
  },
  watch: {
    addressInfo: {
      handler: function(newValue) {
        console.log(newValue);
        this.ruleForm = { ...newValue };
      },
      deep: true,
    },
  },
  mounted() {
    if (this.addressInfo) {
      this.ruleForm = { ...this.addressInfo };
    }
  },
  methods: {
    areaChange(dataObj) {
      this.ruleForm.provinceId = dataObj.areaIds[0];
      this.ruleForm.cityId = dataObj.areaIds[1];
      this.ruleForm.areaId = dataObj.areaIds[2];
    },
    submit() {
      this.$refs.ruleFormRef.validate((valid) => {
        if (valid) {
          const isNew = !this.ruleForm.id;
          if (isNew) {
            addAddressApi(this.ruleForm).then(() => {
              this.$emit("cancle");
              this.$emit("afterOperate");
            });
          } else {
            updateAddressApi(this.ruleForm).then(() => {
              this.$emit("cancle");
              this.$emit("afterOperate");
            });
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>
